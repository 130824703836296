@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import "Vars";

*{
  box-sizing: border-box;
}

body{
  font-family: 'Roboto', sans-serif;
  min-height:100vh;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.001em;
  color: #6E7184;
}

p, li{
  line-height: 160%;
  color: #6E7184;
}

li{
  margin-bottom:15px;
}

#root{
  min-height:100vh;
}

.loadingScreen{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height:100vh;
}


h1,h2,h3,h4,h5,h6{
  font-weight: 500;
  font-size: 24px;
  margin:0 0 20px 0;
  color: #091826;
}

strong{
  font-weight: 500;
  color: #6E7184;
}

.SelfieApp {
  text-align: center;
  background-color: $background-primary;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height:1.2;
  color: $text-primary;
  width: 100%;
}

.SelfieApp-logo {
  max-height: 70px;
  width:auto;
  pointer-events: none;
}

.SelfieApp-header,
.SelfieApp-footer{
  display:block;
  background: white;
  padding: 30px;
  width:100%;
}

.SelfieApp-footer{
  color: #9194A7;
  padding: 20px;
  font-size: 12px;

  p{
    margin: 0 0 10px 0;
  }
}

.SelfieApp-content{
  display:block;
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width:100%;
  position: relative;

  .inner{
    padding:30px;
    width: 100%;
    max-width: 700px;
  }

  &__box{
    display:block;
    padding: 30px;
    background: white;
    border: 1px solid #E5E7F0;
    box-shadow: 0px 1px 4px rgba(31, 36, 71, 0.1);
    border-radius: 8px;
  }
  &__icon{
    margin-bottom:20px;
  }
  ol{
    text-align: left;
    padding-left: 30px;
  }
}

.alignleft{
  text-align:left!important;
  padding-left:16px;
}

.SelfieApp-link {
  color: #61dafb;
}